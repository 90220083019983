import { defineStore } from 'pinia'

export const messages = {
  failedToGetSpecialistIntervals: 'Не удалось получить доступные даты для записи.',
}

type State = {
  specialistIntervals: any[]
}

const initialState = (): State => ({
  specialistIntervals: []
})

export const useSpecialistStore = defineStore('specialist', {
  state: () => initialState(),
  getters: {
    getSpecialistIntervals: (state: State) => state.specialistIntervals
  },
  actions: {
    async fetchSpecialistIntervals (specialistId: string): Promise<void> {
      const { $apiHelper, $toast } = useNuxtApp()

      try {
        const response = await $apiHelper.specialists.getSpecialistIntervals(specialistId) as any
        this.specialistIntervals = response.meta.intervals
      } catch (error) {
        console.error(error)
        $toast.error(messages.failedToGetSpecialistIntervals)
      }
    },
  }
})
